import axios from 'axios';

const API_URL = process.env.VUE_APP_API+'table/';

class TableService {
    refillBox(id, amount) {
        return axios.post(API_URL + 'refillBox/', {id: id, amount: amount});
    }
    withdrawBox(id, amount) {
        return axios.post(API_URL + 'withdrawBox/', {id: id, amount: amount});
    }
    getBoxHistory(id, cnt)
    {
        return axios.post(API_URL + 'getBoxHistory/', {id: id, cnt: cnt});
    }
    lockBox(id)
    {
        return axios.post(API_URL + 'lockBox/', {id: id});
    }
    unlockBox(id)
    {
        return axios.post(API_URL + 'unlockBox/', {id: id});
    }
    unlockBoxStat(id)
    {
        return axios.post(API_URL + 'unlockBoxStat/', {id: id});
    }
}

export default new TableService();