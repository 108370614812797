<template>
  <div class="workspace">
    <div class="overlay" :class="modal"></div>
    <div class="modal" :class="modal">
      <div v-if="boxHistory.total_refill !== undefined">
        <h2>{{t('tables.history.History of refill/withdraw box')}} {{boxHistory.box_id}} [{{t('tables.history.Table')}}{{boxHistory.table_id}}]</h2>
        {{t('tables.history.Shift')}}: {{boxHistory.period}}
      </div>
      <div class="trans_table" v-if="boxHistory.total_refill !== undefined">
        <table class="table table-sm box_table">
          <tr>
            <th>ID</th>
            <th>{{t('tables.history.Date')}}</th>
            <th>{{t('tables.history.Amount')}}</th>
            <th>{{t('tables.history.Description')}}</th>
          </tr>
          <tr v-for="trans in boxHistory.trans" v-bind:key="trans.id" :class="trans.trans_class">
            <td>{{trans.id}}</td>
            <td>{{trans.dt}}</td>
            <td>{{trans.money_size}}</td>
            <td>{{t('tables.history.ttype.'+trans.trans_type)}}</td>
          </tr>
          <tr>
            <td colspan="4" align="left">{{t('tables.history.Total refill')}}: {{boxHistory.total_refill}}</td>
          </tr>
          <tr>
            <td colspan="4" align="left">{{t('tables.history.Total withdraw')}}: {{boxHistory.total_withdraw}}</td>
          </tr>
        </table>
      </div>
      <div v-if="boxHistory.total_refill === undefined">
        <h2>{{t('tables.history.No data found')}}</h2>
      </div>
      <div>
        <span v-if="boxHistory.total_refill !== undefined">{{boxHistory.cnt}} {{$t('tables.history.of')}} {{boxHistory.cnt_all}} {{$t('tables.history.shown')}}</span><br><button v-if="boxHistory.cnt !== boxHistory.cnt_all" @click="getBoxHistory(boxHistory.box_id, null)">{{$t('tables.history.Show all')}}</button>
        <button @click="close_modal" style="margin-left: 10px;">{{$t('cashier.button_ok')}}</button>
      </div>
    </div>
    <div class="table_box" v-for="table in tables" v-bind:key="table.table_id">
      <div>
        <span class="table_name">{{table.table_name}} <span :class="table.table_class">({{table.table_status}})</span></span>
        <table class="table table-sm box_table">
          <tr>
            <th>#</th>
            <th>{{t('tables.Balance')}}</th>
            <th>{{t('tables.State')}}</th>
            <th>{{t('tables.Buttons')}}</th>
          </tr>
          <tr v-for="box in table.boxes" v-bind:key="box.box_id">
            <td>{{box.box_num}}</td>
            <td>{{box.balance}}</td>
            <td>{{t('tables.state.'+box.state)}}<span v-if="box.state === 'blocked_pin'">{{box.pin}})</span></td>
            <td v-if="mode === 'normal' || cur_box_id !== box.box_id">
              <button class="tbl_buttons" @click="goRefill(box.box_id)" v-if="table.table_status === 'online'">{{t('tables.Deposit')}}</button>
              <button class="tbl_buttons" @click="goHistory(box.box_id)">{{t('tables.History')}}</button>
              <button class="tbl_buttons" @click="goWithdraw(box.box_id, box.payout_sum)" v-if="table.table_status === 'online' && box.state === 'blocked_payout'">{{t('tables.Withdraw')}}</button>
              <button class="tbl_buttons" @click="lockBox(box.box_id)" v-if="table.table_status === 'online' && box.state === 'ready'">{{t('tables.Lock')}}</button>
              <button
                  class="tbl_buttons"
                  @click="unlockBox(box.box_id)"
                  v-if="table.table_status === 'online' && (box.state === 'blocked_cashier' || box.state === 'blocked_error')"
              >{{t('tables.Unlock')}}</button>
              <button
                  class="tbl_buttons"
                  @click="unlockBoxStat(box.box_id)"
                  v-if="table.table_status === 'online' && (box.state === 'blocked_stat_pending')"
              >{{t('tables.Unlock stat')}}</button>
            </td>
            <td v-if="mode === 'refill' && cur_box_id === box.box_id">
              <input v-model="cur_amount">
              <button class="alt_tbl_btn" :disabled="disBut" @click="refillBox">{{t('tables.Deposit')}}</button>
              <button class="alt_tbl_btn" @click="goNormal">{{t('tables.Cancel')}}</button>
            </td>
            <td v-if="mode === 'withdraw' && cur_box_id === box.box_id">
              <span>{{cur_withdraw_amount}}</span>
              <button class="alt_tbl_btn" :disabled="disBut" @click="withdrawBox">{{t('tables.Withdraw')}}</button>
              <button class="alt_tbl_btn" @click="goNormal">{{t('tables.Cancel')}}</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import tableService from '../services/table.service';

export default {
  name: "Tables",
  setup()
  {
    const tables = inject('tables')
    const set_result = inject('set_result')
    const updateTables = inject('updateTables')
    const updateBalance = inject('updateBalance')
    const t = inject('$t')

    const mode = ref('normal')
    const cur_box_id = ref(0)
    const cur_amount = ref(0)
    const cur_withdraw_amount = ref(0)
    const modal = ref('')
    const disBut = ref(false)

    const goNormal = () => {
      mode.value = 'normal'
      cur_box_id.value = 0
      cur_amount.value = 0
      cur_withdraw_amount.value = 0
    }

    const goRefill = (id) => {
      mode.value = 'refill'
      cur_box_id.value = id
    }

    const goWithdraw = (id, amount) => {
      mode.value = 'withdraw'
      cur_box_id.value = id
      cur_withdraw_amount.value = amount
    }

    const goHistory = (id) => {
      modal.value = 'open';
      getBoxHistory(id, 10)
    }

    const close_modal = () => {
      modal.value = '';
      boxHistory.value = '';
    }

    const boxHistory = ref ('');

    const lockBox = (id) =>
    {
      tableService.lockBox(id).then(
          (response) => {
            if(!response.data.result)
            {
              alert(t('cashier.error.Cannot lock box:')+' '+t('cashier.error.'+response.data.message));
            }
            else
            {
              updateTables()
            }
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const unlockBox = (id) =>
    {
      tableService.unlockBox(id).then(
          (response) => {
            if(!response.data.result)
            {
              alert(t('cashier.error.Cannot unlock box:')+' '+t('cashier.error.'+response.data.message));
            }
            else
            {
              updateTables()
            }
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const unlockBoxStat = (id) =>
    {
      tableService.unlockBoxStat(id).then(
          (response) => {
            if(!response.data.result)
            {
              alert(t('cashier.error.Cannot unlock box:')+' '+t('cashier.error.'+response.data.message));
            }
            else
            {
              updateTables()
            }
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const getBoxHistory = (id, cnt) =>
    {
      tableService.getBoxHistory(id, cnt).then(
          (response) => {
            boxHistory.value = response.data
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const refillBox = () =>
    {
      disBut.value = true;
      tableService.refillBox(cur_box_id.value, cur_amount.value).then(
          (response) => {
            if(!response.data.result)
            {
              alert(t('cashier.error.Cannot refill box:')+' '+t('cashier.error.'+response.data.message));
            }
            else
            {
              updateTables()
              updateBalance()
              goNormal()
            }

            disBut.value = false;
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const withdrawBox = () =>
    {
      disBut.value = true;
      tableService.withdrawBox(cur_box_id.value, cur_withdraw_amount.value).then(
          (response) => {
            if(!response.data.result)
            {
              alert(t('cashier.error.Cannot withdraw box:')+' '+t('cashier.error.'+response.data.message));
            }
            else
            {
              updateTables()
              updateBalance()
              goNormal()
            }
            disBut.value = false;
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    return {
      tables,
      goRefill,
      mode,
      cur_box_id,
      goNormal,
      updateTables,
      refillBox,
      cur_amount,
      t,
      withdrawBox,
      goWithdraw,
      cur_withdraw_amount,
      modal,
      goHistory,
      close_modal,
      boxHistory,
      getBoxHistory,
      lockBox,
      unlockBox,
      unlockBoxStat,
      disBut
    }
  }
}

</script>

<style scoped>
  .workspace
  {
    background-color: #DDDDDD;
    overflow: auto;
  }
  .table_box
  {
    border: 1px solid;
    margin: 15px;
    background-color: white;
    width: 90%;
  }
  @media screen and (max-width: 400px) {
    .table_box {
      width: 35%;
    }
    .box_table
    {
      margin-top: 20px;
      width: 45%;
    }
    .modal
    {
      width: 370px !important;
      left: 3px !important;
    }
    .tbl_buttons
    {
      margin-top: 3px;
    }
    .alt_tbl_btn
    {
      margin-top: 3px;
    }
  }
  @media screen and (max-height: 700px) {
    .modal
    {
      height: 600px !important;
      top: 3px !important;
    }
    .trans_table
    {
      height: 60% !important;
    }
  }
  .table_name
  {
    font-weight: bold;
    font-size: 130%;
    padding: 8px;
  }
  .box_table
  {
    margin-top: 20px;
    width: 95%;
  }
  .tbl_buttons
  {
    margin-right: 5px;
    border-radius: 10px;
  }
  .alt_tbl_btn
  {
    margin-left: 5px;
    border-radius: 10px;
  }
  .modal
  {
    background-color: #CCCCCC;
    height: 90%;
    width: 80%;
    top: 5%;
    left: 10%;
    border: 3px solid;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: baseline;
  }
  .overlay
  {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #464d55;
  }
  .open
  {
    display: block !important;
  }
  .trans_table
  {
    height: 75%;
    overflow: auto;
  }
  .green
  {
    color: green;
  }
  .grey
  {
    color: grey;
  }
  .red
  {
    color: red;
  }
</style>